* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f1f1f1;
}

.app {
}
