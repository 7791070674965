.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: rgb(255, 189, 61);
}

.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: rgb(255, 189, 61);
}

.slick-dots li.slick-active button:before {
  color: rgb(255, 189, 61);
}

.slick-dots li button:hover:before {
  color: rgb(255, 189, 61);
}

.slick-dots li button {
  outline: 0;
}
